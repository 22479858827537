import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import App from "../components/App"

const IndexPage = () => (
    <Layout>
      <SEO title="Internet ledger of truth" keywords={['Proofivy']} />
        <App/>
    </Layout>
)

export default IndexPage
