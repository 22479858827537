import React from "react";
import { Link } from 'gatsby';
// import { StaticImage } from "gatsby-plugin-image"
import fingerprint from "../images/fingerprint.svg"
import immutable_file from "../images/immutable_file.svg"
import app_store from "../images/download_on_apple_app_store.svg"
import cai from "../images/CAI_Lockup_RGB_Black.svg"
import ip_lock from "../images/ip_lock_logo.svg"

class App extends React.Component {
  state = {
  }

  render() {
    return (
      <div style={{ maxWidth: `1200px`, marginBottom: `1.45rem` , 'marginLeft': '4px', 'marginRight': '4px'}} align="left">
          <h1>Proofivy lets users protect and claim their IP, ideas, and copyright.<br />
            An immutable and timestamped ledger of truth.<br />
             We make it easy for researchers, creators, and anyone, to prove the creation and possession of data and IP,
              even before they are ready to share it.
          </h1>
          <h2>Commit a fingerprint of your plans and files to the Ethereum blockchain. Immutable and timestamped proof
           without sharing or uploading any data</h2>
          <img src={fingerprint} alt="Fingerprint" style={{ height: "150px" }}/>
          <h2><Link to="/use_cases">Use cases</Link></h2>
          <h4><Link to="/what_is_proofivy">More info</Link></h4>
          <div align="right">
          <div style={{display: 'flex',  gap: '10px'}}>
          <a href="https://apps.apple.com/app/proofivy/id6446280271">
            <img src={app_store} alt="Apple App Store" style={{ height: "50px" }}/>
          </a>
          <p> </p>
          <a href="https://ip-lock.proofivy.com">
            <img src={ip_lock} alt="IP lock" style={{ height: "50px" }}/>
          </a>
          </div>
          <p> </p>
          <a href="https://contentauthenticity.org">
            <img src={cai} alt="Content Authenticity Initiative" style={{ height: "48px" }}/>
          </a>
          </div>
      </div>
    );
  }
}

export default App;
